<template>
  <v-row>
    <v-col
      cols="12"
      lg="2"
      xl="3"
    >
      <profile-card
        :username="username"
        :platform="platform"
        :accountId="accountId"
        :reportId="reportId"
        :reportUserId="reportUserId"
        :user-data="userData"
        :created-at="createdAt"
        :generated-at="generatedAt"
        :isViewingOldReport="isViewingOldReport"
      />
    </v-col>

    <v-col
      cols="12"
      lg="10"
      xl="9"
    >
      <div
        v-if="isViewingOldReport"
        class="d-flex align-center rounded orange white--text py-3 px-4 mb-4"
      >
        <v-icon color="white">warning</v-icon>

        <div class="ml-3">
          You are viewing an old report. Some data may be outdated.
        </div>

        <v-spacer />

        <v-btn
          depressed
          color="white"
          class="orange--text ml-3"
          @click="switchReportToLatest"
        >
          Switch Report
        </v-btn>
      </div>

      <v-tabs
        background-color="transparent"
        v-model="tabNavigation"
        icons-and-text
        color="primary"
        show-arrows
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-overview">
          Overview
          <v-icon>data_saver_off</v-icon>
        </v-tab>

        <v-tab href="#tab-engagement">
          Engagement
          <v-icon>bar_chart</v-icon>
        </v-tab>

        <v-tab href="#tab-audience">
          Audience
          <v-icon>groups</v-icon>
        </v-tab>

        <v-tab href="#tab-posts">
          Posts
          <v-icon>image</v-icon>
        </v-tab>

        <v-tab v-if="shouldShowReelsTab" href="#tab-reels">
          Reels
          <v-icon>movie</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabNavigation" :dark="isThemeDark">
        <v-tab-item value="tab-overview">
          <v-card flat color="accent">
            <v-card-text :class="{ 'black--text': isThemeLight }">
              <overview :platform="platform" :user-data="userData" :overview-data="overviewData" :audience-data="audienceData" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-engagement">
          <v-card flat color="accent">
            <v-card-text :class="{ 'black--text': isThemeLight }">
              <engagement :platform="platform" :user-data="userData" :engagement-data="engagementData" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-audience">
          <v-card flat color="accent">
            <v-card-text :class="{ 'black--text': isThemeLight }">
              <audience :platform="platform" :user-data="userData" :audience-data="audienceData" :total-values="totalValues" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-posts">
          <v-card flat color="accent">
            <v-card-text :class="{ 'black--text': isThemeLight }">
              <posts :platform="platform" :user-data="userData" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item v-if="shouldShowReelsTab" value="tab-reels">
          <v-card flat color="accent">
            <v-card-text :class="{ 'black--text': isThemeLight }">
              <reels :platform="platform" :user-data="userData" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
// Import children components
const Posts = () => import(/* webpackChunkName: "blocks-posts" */ "@/blocks/user-profile/Posts.vue")
const Reels = () => import(/* webpackChunkName: "blocks-reels" */ "@/blocks/user-profile/Reels.vue")
const Overview = () => import(/* webpackChunkName: "blocks-overview" */ "@/blocks/user-profile/Overview.vue")
const Audience = () => import(/* webpackChunkName: "blocks-audience" */ "@/blocks/user-profile/Audience.vue")
const Engagement = () => import(/* webpackChunkName: "blocks-engagement" */ "@/blocks/user-profile/Engagement.vue")
const ProfileCard = () => import(/* webpackChunkName: "blocks-profile-card" */ "@/blocks/user-profile/ProfileCard.vue")

// Export the SFC
export default {
  // Name of the component
  name: "AppView",

  // Accept incoming data from parent
  props: {
    username: {
      type: String,
      required: true
    },

    platform: {
      type: String,
      required: true
    },

    accountId: {
      type: [String, Number],
      required: false,
      default: null
    },

    reportId: {
      type: [String, Number],
      required: false,
      default: null
    },

    reportUserId: {
      type: [String, Number],
      required: false,
      default: null
    },

    userData: {
      type: Object,
      required: true
    },

    createdAt: {
      type: Number,
      required: true
    },

    generatedAt: {
      type: Number,
      required: true,
    },

    overviewData: {
      type: Object,
      required: true
    },

    engagementData: {
      type: Object,
      required: true
    },

    audienceData: {
      type: Object,
      required: true
    },

    totalValues: {
      type: Object,
      required: true
    },

    isViewingOldReport: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Register components
  components: {
    // Shown in the first grid section
    ProfileCard,

    // Shown in second grid section, as tab elements
    Overview,
    Engagement,
    Audience,
    Posts,
    Reels
  },

  // Local data variables
  data: () => ({
    // For the tabs menu between overview, engagements, audience, posts and reels
    tabNavigation: null
  }),

  // Computable readonly data variables
  computed: {
    /**
     * Whether or not to show the reels tab option
     *
     * @returns {Boolean}
     */
    shouldShowReelsTab() {
      // Only show this tab for instagram profiles
      return this.platform === "instagram"
    }
  },

  // Define local method functions
  methods: {
    /**
     * Switch the report to the latest version
     *
     * @returns {void}
     */
    async switchReportToLatest() {
      // Replace the URL
      await this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          accountId: this.$route.query.accountId || this.accountId || this.username,
        }
      })

      // Fire an event
      window.dispatchEvent(
        new CustomEvent("InsightReportUpdate", {
          detail: {
            platform: this.platform,
            accountId: this.accountId,
          }
        })
      )
    }
  }
}
</script>
